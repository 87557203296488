<template>
  <div class="k-9sptci" v-loading="loading">
    <!-- 查询数据 -->
    <div slot class="clearfix">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="狼人杀编号&昵称">
          <el-input
            placeholder="请输入狼人杀uid或者名称"
            v-model="searchForm.uidAndName"
            @keyup.enter.native="handleQuery()"
          />
        </el-form-item>

        <el-button
          @click="handleQuery()"
          style="margin-left: 20px; padding: -9px 30px; font-size: 15px"
          type="primary"
          >查 询</el-button
        >
      </el-form>
    </div>

    <el-table border :data="dataTable">
      <el-table-column
        align="center"
        prop="uid"
        label="狼人杀编号"
      ></el-table-column>

      <el-table-column
        align="center"
        prop="name"
        label="狼人杀昵称"
      ></el-table-column>

      <el-table-column label="狼人杀头像">
        <template slot-scope="scope">
          <div v-if="scope.row.avatar">
            <img :src="scope.row.avatar" class="avatar" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="wechat"
        label="狼人杀微信号"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template v-slot="{ row }">
          <el-button @click="handleEdit(row)" style="margin-left: 10px"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="账号编辑"
      :visible.sync="userVisible"
      v-if="userVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="userForm" :model="userData" label-width="100px">
        <el-form-item label="狼人杀编号：">
          {{ userData.uid }}
        </el-form-item>
        <el-form-item label="狼人杀头像：">
          <img :src="userData.avatar" class="avatar" />
        </el-form-item>
        <el-form-item label="狼人杀昵称：">
          {{ userData.name }}
        </el-form-item>
        <el-form-item
          label="微信号"
          prop="wechat"
          :rules="[
            {
              required: true,
              message: '请输入微信号',
              trigger: ['blur'],
            },
          ]"
        >
          <el-input
            v-model="userData.wechat"
            placeholder="请输入微信号"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="userVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

import { MessageBox } from "element-ui";
export default {
  name: "account",
  computed: {
    ...mapState("match", ["match_types"]),
  },

  data() {
    return {
      maps: new Map(),
      options: [],
      pager: {
        total: 10,
        pageNum: 1,
        pageSize: 10,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        uidAndName: "",
      },
      dataTable: [],
      userVisible: false,
      view_type: "add",
      userData: {
        avatar: "",
        name: "",
        uid: "",
        wechat: "",
        _id: "",
      },
      match: false,
    };
  },
  methods: {
    //编辑赋值
    async handleEdit(row) {
      this.userData._id = row._id;
      this.userData.wechat = row.wechat;
      this.userData.uid = row.uid;
      this.userData.avatar = row.avatar;
      this.userData.name = row.name;
      this.userVisible = true;
    },
    //添加banner
    saveBanner() {
      this.$refs.userForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;

          let { data, code } = await this.$http.operate.save_wechat({
            _id: this.userData._id,
            we_chat_number: this.userData.wechat,
          });
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.userVisible = false;

            this.handleQuery();
          }
        }
      });
    },

    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      let pageSize = 10;
      this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.operate.user_api_list({
        ...this.searchForm,
        pageNum,
        pageSize,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },

    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    //查询列表
    this.handleQuery();
  },
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
